@import '../../global/styles/lib';

.hero-banner {
    @include full-bleed;
    position: absolute;
    width: 100%;
    height: 100%;

    &::before {
        content: "";
        @include absolute-full;
        z-index: 1;
    } // &::before
    
    .img-container {
        &::before {
            content: "";
            @include absolute-full;
            background-image: linear-gradient(0deg, $transparent 25%, rgba($black, 0.45) 100%);
            z-index: 1;
        } // &::before

        img {
            @include absolute-full;
            object-fit: cover;
            object-position: top;
        } // img
        
    } // .img-container

    .content {
        position: absolute;
        text-align: center;
        top: 33%;
        left: 0%;
        width: 100%;
        height: 100%;
        z-index: 2;
        h1,
        h3 {
            color: $white !important;
        } // h1, h3

        h1 {
            font-size: 1.25rem !important; 
        } // h1
        h3 {
            text-transform: unset !important;
            font-style: italic !important;
            font-family: $essonnes !important;
            opacity: 0.55;
            font-size: 1rem !important;
            margin-top: 0.5rem !important;
        } // h3

    } // .content

    &.face {
        .content {
            top: 66%;
        } // .content
    } // &.face

    @include breakpoint(medium up) {
            .content {
                h1 {
                    font-size: 2.5rem !important; 
                } // h1
                h3 {
                    text-transform: unset !important;
                    font-style: italic !important;
                    opacity: 0.55;
                    font-size: 1.5rem !important;
                    margin-top: 0.5rem !important;
                } // h3    
            }
    } // medium up

    @include breakpoint(large up) {
        .content {    
            h1 {
                font-size: 3.5rem !important; 
            } // h1
            h3 {
                text-transform: unset !important;
                font-style: italic !important;
                opacity: 0.55;
                font-size: 2rem !important;
                margin-top: 0.5rem !important;
            } // h3    
        } // .content
    } // large up
} // .hero-banner

// No gradient on homepage
.home {
    .hero-banner {
        .img-container {
            &::before {
                background-image: none;
            } // &::before
            
        } // .img-container
    } // .hero-banner
} // .home